import { useRef } from "react";
import About from "./about";
import Footer from "./footer";
import Header from "./header";
import Info from "./info";
import UseCases from "./useCases";
import WhyMaha from "./whyMaha";

function App() {
  const aboutRef = useRef(null);
  const whyMahaRef = useRef(null);
  const useCaseRef = useRef(null);

  return (
    <div>
      <Header
        aboutRef={aboutRef}
        whyMahaRef={whyMahaRef}
        useCaseRef={useCaseRef}
      />
      <About aboutRef={aboutRef} />
      <Info />
      <WhyMaha whyMahaRef={whyMahaRef} />
      <UseCases useCaseRef={useCaseRef} />
      <Footer />
    </div>
  );
}

export default App;
