import { Link } from "react-scroll";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";
import Hamburger from "hamburger-react";
import "./styles.css";
const image = require("./logo.png");
const Header = ({ aboutRef, whyMahaRef, useCaseRef }) => {
  const matches = useMediaQuery("(min-width:1024px)");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const scrollToRef = (ref) => {
    setAnchorEl(null);
    console.log(ref);
    ref.current.scrollIntoView();
  };
  return (
    <div
      className="header"
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: "5vw",
        paddingRight: "5vw",
      }}
    >
      <img src={image} className="logo" alt="About image" />
      {matches ? (
        <nav
          style={{
            width: "30%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <Link style={{ cursor: "pointer" }} to="about" activeClass="active">
            About
          </Link>
          <Link
            style={{ cursor: "pointer" }}
            to="why-maha"
            activeClass="active"
          >
            Why Maha
          </Link>
          <Link
            style={{ cursor: "pointer" }}
            to="use-cases"
            activeClass="active"
          >
            Use Cases
          </Link>
        </nav>
      ) : (
        <>
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <Hamburger color="black" toggled={open} toggle={handleClick} />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={() => scrollToRef(aboutRef)}>About</MenuItem>
            <MenuItem onClick={() => scrollToRef(whyMahaRef)}>
              Why Maha
            </MenuItem>
            <MenuItem onClick={() => scrollToRef(useCaseRef)}>
              Use Cases
            </MenuItem>
          </Menu>
        </>
      )}
    </div>
  );
};

export default Header;
