import React from "react";
import "./styles.css";
const Info = () => {
  return (
    <>
      <div className="curve"></div>
      <div className="info">
        <div className="infoText">
          <h1>A financial content platform to engage with your community</h1>
        </div>
        <div className="infoText">
          <p>
            Leverage technology to set your clients up for success by giving
            them the knowledge and skills they need to reach their financial
            goals.
          </p>
        </div>
      </div>
    </>
  );
};

export default Info;
