import React from "react";
import "./styles.css";
const logo = require("./logo.png");

const Footer = () => {
  const image = require("./footer.png");
  return (
    <div className="footer">
      <div className="footerImageBase">
        <img className="footerImage" src={image} />
      </div>
      <div className="footerInfo">
        <div className="footerInfoBase">
          <div className="row">
            <img src={logo} className="footerLogo" />
            <div className="footerBaseInner">
              <h4>Contact details</h4>
              <div className="footerRow">
                <a className="footerCell" href="mailto:info@joinmaha.com">
                  info@joinmaha.com
                </a>
                <p className="footerCell">
                  The GrEEK Campus, 171 Tahrir Street, Cairo, Egypt 11513
                </p>
              </div>
            </div>
          </div>
          <div className="footerRow">
            <div className="footerCellCopy">
              <h5>© 2023 Hassala Technology</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
